import { LocationState } from '@core/Entities/Search/LocationState.entity';
import { atomWithStorage } from 'jotai/utils';

export const locationAtom = atomWithStorage<LocationState>('locationValue', {
  isValid: false,
  location: {
    lat: 0,
    lon: 0,
  },
  error: false,
  placeName: '',
});
